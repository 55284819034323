import OidcRoutes from 'authentication/components/oidc/Routes';

import OidcConfiguration from './OidcConfiguration';
import OidcLogin from './OidcLogin';
import OidcCallbackPage from './OidcCallbackPage';

export default {
  routes: [
    { path: OidcRoutes.unqualified.AUTH_CALLBACK, component: OidcCallbackPage },
  ],
  authenticatorConfigurations: [
    {
      name: 'OIDC',
      displayName: 'OpenID Connect',
      description: 'Authenticates users via OpenID Connect.',
      canBeDisabled: false,
      component: OidcConfiguration,
    },
  ],
  loginProviderType: [
    {
      type: 'oidc-v1',
      formComponent: OidcLogin,
    },
  ],
};
