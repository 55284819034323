import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import { v4 as uuid } from 'uuid';

import { Spinner } from 'components/common';
import connect from 'stores/connect';
import Store from 'logic/local-storage/Store';
import OidcRoutes from 'authentication/components/oidc/Routes';

import OidcLoginForm from './OidcLoginForm';
import OidcCallback from './OidcCallback';
import { OidcConfigurationStore } from './OidcConfigurationStore';
import { OidcSessionStore } from './OidcSessionStore';

const OidcLogin = ({ configuration, isLoading, onErrorChange }) => {
  const uri = new URI(window.location.href);

  const isCallbackUrl = () => uri.path() === OidcRoutes.AUTH_CALLBACK;

  const generateNonce = (redirectTo = uri.resource()) => ({
    state: uuid(),
    redirectTo: redirectTo,
  });

  const [nonce, setNonce] = useState(() => {
    try {
      if (isCallbackUrl()) {
        return Store.sessionGet('nonce');
      }
    } catch (e) {
      return generateNonce();
    }

    return generateNonce();
  });

  const regenerateNonce = () => {
    setNonce((prevNonce) => generateNonce(prevNonce.redirectTo));
  };

  useEffect(() => {
    Store.sessionSet('nonce', nonce);
  }, [nonce]);

  if (isLoading || !configuration) {
    return (
      <p className="loading-text">
        <Spinner text="Loading, please wait..." delay={0} />
      </p>
    );
  }

  if (isCallbackUrl()) {
    return (
      <OidcCallback configuration={configuration}
                    nonce={nonce}
                    onErrorChange={onErrorChange}
                    params={uri.query(true)}
                    regenerateNonce={regenerateNonce} />
    );
  }

  return (
    <OidcLoginForm checkSession configuration={configuration} nonce={nonce} onErrorChange={onErrorChange} />
  );
};

OidcLogin.propTypes = {
  configuration: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onErrorChange: PropTypes.func.isRequired,
};

OidcLogin.defaultProps = {
  configuration: undefined,
};

export default connect(OidcLogin, {
  configuration: OidcConfigurationStore,
  oidcSession: OidcSessionStore,
}, ({ configuration, oidcSession, ...props }) => ({
  ...props,
  configuration: configuration.configuration,
  isLoading: oidcSession.isLoading,
}));
