import { qualifyUrls } from 'routing/Routes';

export const reportsPrefix = '/reports';

export const contentSuffix = '/content';
export const deliverySuffix = '/delivery';
export const styleSuffix = '/style';

const ReportRoutes = {
  OVERVIEW: reportsPrefix,
  NEW: `${reportsPrefix}/new`,
  CREATE: {
    CONTENT: `${reportsPrefix}/new${contentSuffix}`,
    DELIVERY: `${reportsPrefix}/new${deliverySuffix}`,
    STYLE: `${reportsPrefix}/new${styleSuffix}`,
  },
  configuration: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/configuration`,
  contents: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/contents`,
  archive: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/archive`,
  render: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/render`,
  EDIT: {
    overview: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/contents`,
    content: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/contents${contentSuffix}`,
    delivery: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/contents${deliverySuffix}`,
    style: (reportId: string = ':reportId') => `${reportsPrefix}/${reportId}/contents${styleSuffix}`,
  },
} as const;
const routes = qualifyUrls(ReportRoutes);

export default {
  ...routes,
  unqualified: ReportRoutes,
};
