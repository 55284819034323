import type { PluginExports } from 'graylog-web-plugin/plugin';

import ReportConfigurationPage from 'report/report-configuration-page/ReportConfigurationPage';
import ReportContentsPage from 'report/report-contents-page/ReportContentsPage';
import ReportHistoryPage from 'report/report-history-page/ReportHistoryPage';
import ReportRenderPage from 'report/report-render-page/ReportRenderPage';
import ReportsPage from 'report/reports-page/ReportsPage';
import isDashboardPageUsedInReports from 'report/logic/IsDashboardPageUsedInReports';
import isDashboardUsedInReports from 'report/logic/IsDashboardUsedInReports';
import isWidgetUsedInReports from 'report/logic/IsWidgetUsedInReports';
import ReportingConfig from 'report/ReportingConfig';
import AppConfig from 'util/AppConfig';
import CreateReportPage from 'report/report-creation/CreateReportPage';
import ReportRoutes from 'report/ReportRoutes';
import EditReportPage from 'report/report-creation/EditReportPage';
import EditReportContentsPage from 'report/report-contents-page/EditReportContentsPage';

const NEW_REPORT_CREATION_ENABLED = AppConfig.isFeatureEnabled('new_report_creation');
const bindings: PluginExports = {
  routes: [
    { path: ReportRoutes.unqualified.OVERVIEW, component: ReportsPage, permissions: 'inputs:create' },
    { path: `${ReportRoutes.unqualified.NEW}/*`, component: NEW_REPORT_CREATION_ENABLED ? CreateReportPage : ReportContentsPage, permissions: 'inputs:create' },
    { path: '/reports/legacy', component: ReportContentsPage, permissions: 'inputs:create' },
    { path: ReportRoutes.unqualified.configuration(), component: ReportConfigurationPage, permissions: 'inputs:create' },
    { path: `${ReportRoutes.unqualified.contents()}/*`, component: NEW_REPORT_CREATION_ENABLED ? EditReportPage : EditReportContentsPage, permissions: 'inputs:create' },
    { path: ReportRoutes.unqualified.archive(), component: ReportHistoryPage, permissions: 'inputs:create' },
    {
      path: ReportRoutes.unqualified.render(),
      component: ReportRenderPage,
      permissions: 'inputs:create',
      parentComponent: null,
    },
  ],
  'views.hooks.confirmDeletingDashboard': [isDashboardUsedInReports],
  'views.hooks.confirmDeletingDashboardPage': [isDashboardPageUsedInReports],
  'views.hooks.confirmDeletingWidget': [isWidgetUsedInReports],
  systemConfigurations: [{
    component: ReportingConfig,
    displayName: 'Reporting',
    configType: 'org.graylog.plugins.report.configuration.ReportingSystemConfiguration',
  }],
};

export default bindings;
