import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import workspaceV2Routes from './Routes';

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const WorkspaceV2InputConfiguration = ({ url }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

WorkspaceV2InputConfiguration.propTypes = {
  url: PropTypes.string,
};

WorkspaceV2InputConfiguration.defaultProps = {
  url: workspaceV2Routes.INTEGRATIONS.WorkspaceV2.ACTIVITYAPI.index,
};

export default WorkspaceV2InputConfiguration;
