import { qualifyUrls } from 'routing/Routes';

const OidcRoutes = {
  AUTH_CALLBACK: '/authorization-code/callback',
} as const;

export default {
  ...qualifyUrls(OidcRoutes),
  unqualified: OidcRoutes,
};
