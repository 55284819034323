import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import InteractiveContext from 'views/components/contexts/InteractiveContext';
import { BLANK } from 'views/components/contexts/SearchPageLayoutContext';
import SearchPage from 'views/pages/SearchPage';
import SearchPageLayoutProvider from 'views/components/contexts/SearchPageLayoutProvider';
import type { ViewJson } from 'views/logic/views/View';
import ViewDeserializer from 'views/logic/views/ViewDeserializer';

const StyledPageContentLayout = styled.div`
  padding: 0 15px;
`;

const SearchAreaContainer = ({ children }: React.PropsWithChildren) => (
  <StyledPageContentLayout>{children}</StyledPageContentLayout>
);

const MetricsSearchView = ({ viewJson }: { viewJson: ViewJson }) => {
  const view = useMemo(() => ViewDeserializer(viewJson), [viewJson]);

  const searchPageLayoutContextValue = {
    sidebar: { isShown: false },
    viewActions: BLANK,
    searchAreaContainer: { component: SearchAreaContainer },
  };

  return (
    <InteractiveContext.Provider value={false}>
      <SearchPageLayoutProvider value={searchPageLayoutContextValue}>
        <SearchPage view={view} isNew={false} skipNoStreamsCheck />
      </SearchPageLayoutProvider>
    </InteractiveContext.Provider>
  );
};

export default MetricsSearchView;
