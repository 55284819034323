import { qualifyUrls } from 'routing/Routes';

const ArchiveRoutes = {
  LIST: '/system/archives',
  CONFIGURATION: '/system/archives/configuration',
  BACKENDS: {
    LIST: '/system/archives/backends',
    CREATE: '/system/archives/backends/new',
    show: (backendId = ':backendId') => `/system/archives/backends/${backendId}`,
  },
} as const;
const routes = qualifyUrls(ArchiveRoutes);

export default {
  ...routes,
  unqualified: ArchiveRoutes,
};
