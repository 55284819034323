import * as React from 'react';
import { useCallback, useState } from 'react';

import { getPathnameWithoutId, qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import Store from 'logic/local-storage/Store';
import { DATA_WAREHOUSE_API_ROUTES, HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY } from 'data-warehouse/Constants';
import DataWarehouseRoutes from 'data-warehouse/Routes';
import type {
  DataWarehouseActionFormValues,
} from 'data-warehouse/components/DataWarehouseActionModalForm';
import DataWarehouseActionModalForm from 'data-warehouse/components/DataWarehouseActionModalForm';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import useUserDateTime from 'hooks/useUserDateTime';
import fetch from 'logic/rest/FetchProvider';
import { Alert, Button, ButtonToolbar } from 'components/bootstrap';
import { Link } from 'components/common/router';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useLocation from 'routing/useLocation';

import type { DataWarehouse } from '../../Types';

type Props = {
  descriptor: string,
  handleFailures: (failures: Array<{
    entity_id: string
  }>, actionPastTense: string, archiveId: string) => void,
  refetchArchives: () => void,
  onSelect?: () => void,
  archive: DataWarehouse,
  buttonType?: 'menu' | 'button',
};

const RetrievalArchiveAction = ({
  archive,
  handleFailures,
  refetchArchives,
  descriptor,
  onSelect,
  buttonType,
}: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [hideHelpText, setHideHelpText] = useState(Store.get(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY) === 'true');
  const { userTimezone } = useUserDateTime();
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const hideRetrievalHelp = () => {
    Store.set(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY, 'true');
    setHideHelpText(true);
  };

  const onRestoreSubmit = useCallback((values: DataWarehouseActionFormValues) => {
    const uriParams = getDataWarehouseUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.bulk_retrieval().url}?${uriParams}`),
      { stream_ids: [archive.stream_id], inclusion_type: values.inclusion_type },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'submitted', archive.id);
    }).catch((error) => {
      UserNotification.error(`An error occurred while retrieving data. ${error}`);
    }).finally(() => {
      setShowRetrievalModal(false);
      refetchArchives();
    });
  }, [archive, userTimezone, handleFailures, refetchArchives]);

  const onRetrieval = () => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.DATAWAREHOUSE.DATA_RETRIEVAL_OPENED, {
      app_pathname: getPathnameWithoutId(pathname),
    });

    setShowRetrievalModal(true);
  };

  const modalTitle = 'Retrieving from Data Warehouse';
  const isMenuButton = buttonType === 'menu';

  return (
    <ButtonToolbar className="pull-right">
      <Button bsStyle={isMenuButton ? 'default' : 'success'}
              bsSize={isMenuButton ? 'xsmall' : 'medium'}
              onClick={() => onRetrieval()}>Retrieve from Data Warehouse
      </Button>
      {showRetrievalModal && (
        <DataWarehouseActionModalForm modalTitle={modalTitle}
                                      type="retrieval"
                                      show={showRetrievalModal}
                                      onHide={() => setShowRetrievalModal(false)}
                                      submitButtonText="Retrieve"
                                      streamIds={[archive.stream_id]}
                                      onSubmit={onRestoreSubmit}>
          <p>{`You are about to retrieve data from 1 ${descriptor}.`}</p>
          {!hideHelpText && (
            <Alert bsStyle="info" onDismiss={hideRetrievalHelp}>
              This will launch a retrieval operation to route messages from the Data Warehouse into the <b>Search Cluster</b>.<br />
              Retrieved messages that were not previously routed to the <b>Search Cluster </b> will count towards <b>Graylog License</b> usage.<br />
              Note that pulling messages from the Data Warehouse may take some time - progress may be tracked on
              the <Link to={DataWarehouseRoutes.ARCHIVE.LIST}><b>Data Warehouse</b></Link> page.
            </Alert>
          )}
        </DataWarehouseActionModalForm>
      )}
    </ButtonToolbar>
  );
};

RetrievalArchiveAction.defaultProps = {
  onSelect: undefined,
  buttonType: 'menu',
};

export default RetrievalArchiveAction;
