import { qualifyUrls } from 'routing/Routes';

const AzureRoutes = {
  INTEGRATIONS: {
    Azure: {
      ACTIVITYAPI: {
        index: '/integrations/azure',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Azure: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/azure_logs/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/azure_logs/checkCredentials',

    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Azure: {
      GRAYLOG_Azure_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_azure_ActivityLog_Input.html#graylog-azure-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(AzureRoutes),
  unqualified: AzureRoutes,
};

export { DocsRoutes, ApiRoutes };
