import { qualifyUrls } from 'routing/Routes';

const AWSSecurityLakeRoutes = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      ACTIVITYAPI: {
        index: '/integrations/awssecuritylake',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/checkCredentials',
      GET_AWS_REGIONS: '/plugins/org.graylog.enterprise.integrations/awssecuritylake/getawsregions',

    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    AWSSecurityLake: {
      GRAYLOG_AWSSecurityLake_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_AWSSecurityLake_ActivityLog_Input.html#graylog-AWSSecurityLake-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(AWSSecurityLakeRoutes),
  unqualified: AWSSecurityLakeRoutes,
};

export { DocsRoutes, ApiRoutes };
